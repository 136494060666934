<!--

      This component is the foundation for four pages:
      1. Login
      2. Registration
      4. Reset Password
      3. New Password

 -->

<template>
  <SignInUpPage>

    <div v-if="loading">
      <div class="centered loading-signin">
        <div class="headline">Schön, dass du da bist!</div>
        <ion-spinner class="spinner-signin" name="crescent"></ion-spinner>
      </div>
    </div>

    <div v-if="!loading">
      <div class="accept">
        <Icon name="ellipseOutline" class="icon-checkbox icon-50" @click="toggleAccepted()" v-if="!accepted"/>
        <Icon name="checkmarkCircleOutline" class="icon-checkbox icon-50" @click="toggleAccepted()" v-if="accepted" />
        <div class="accept-text">
          <span @click="toggleAccepted()">Ich akzeptiere </span>
          <a @click="$store.dispatch('openLegalDocument', 'agb')">Nutzungsbedingungen</a>
          <span @click="toggleAccepted()"> und </span>
          <a @click="$store.dispatch('openLegalDocument', 'agb')">Datenschutzerklärung</a>.</div>
      </div>
      <div v-if="showFacebookSignIn" class="btn-secondary btn-signinup" @click="this.proceed('facebook')">
        <div class="social-btn-grid">
          <img class="provider-icon" src="../../assets/img/facebook-logo.svg" />
          <span>Mit Facebook anmelden</span>
        </div>
      </div>
      <div class="btn-secondary btn-signinup" @click="this.proceed('google')">
        <div class="social-btn-grid">
          <img class="provider-icon" src="../../assets/img/google-logo.svg" />
          <span>Mit Google anmelden</span>
        </div>
      </div>
      <div v-if="showAppleSignIn" :key="this.$store.state.deviceInfo" class="btn-secondary btn-signinup" @click="this.proceed('apple')">
        <div class="social-btn-grid">
          <img class="provider-icon apple-icon" src="../../assets/img/apple-logo.svg" />
          <span>Mit Apple anmelden</span>
        </div>
      </div>
      <div class="btn-primary btn-signinup" @click="this.proceed('register')" data-cy="submit">
        <div class="social-btn-grid">
          <Icon name="mailOutline" class="email-icon"/>
          <span>Mit E-Mail registrieren</span>
        </div>
      </div>
      <div class="divider-or">
        <div class="divider-line"></div>
        <div class="divider-text">Oder</div>
        <div class="divider-line"></div>
      </div>
      <div class="btn-primary btn-signinup" @click="this.$store.dispatch('goTo', 'login')" data-cy="submit">
        <div class="social-btn-grid">
          <Icon name="mailOutline" class="email-icon"/>
          <span>Mit E-Mail einloggen</span>
        </div>
      </div>
      <div class="btn-tertiary" @click="this.$store.dispatch('writeHelpMail')">Ich brauche Hilfe / habe Feedback</div>
    </div>

  </SignInUpPage>
</template>

<script>
import SignInUpPage from '@/components/SignInUpPage.vue'
import { IonSpinner } from '@ionic/vue'

export default {
  name: 'signin',
  components: {
    SignInUpPage,
    IonSpinner
  },
  data () {
    return {
      accepted: false,
      loading: null
    }
  },
  computed: {
    showAppleSignIn () {
      return this.$store.state.deviceInfo && this.$store.state.deviceInfo.platform === 'ios'
    },
    showFacebookSignIn () {
      return this.$store.state.deviceInfo && (this.$store.state.deviceInfo.platform === 'ios' || this.$store.state.deviceInfo.platform === 'android')
    }
  },
  methods: {
    toggleAccepted () {
      this.accepted = !this.accepted
    },
    async proceed (target) {
      if (!this.accepted) {
        this.$store.dispatch('showNotification', { message: 'Bitte akzeptiere die Nutzungsbedingungen und Datenschutzerklärung indem du auf den Kreis links davon klickst.', type: 'Error' })
      } else {
        this.loading = true
        if (target === 'facebook') {
          await this.$store.dispatch('signInWithFacebook')
        } else if (target === 'google') {
          await this.$store.dispatch('signInWithGoogle')
        } else if (target === 'apple') {
          await this.$store.dispatch('signInWithApple')
        } else if (target === 'register') {
          await this.$store.dispatch('goTo', 'register')
        }
        setTimeout(() => {this.loading = null}, 2000)
      }
    }
  },
  updated () {
    if (!this.$store.state.deviceInfo) {
      this.$store.dispatch('getDeviceInfo')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.btn-signinup {
  width: 270px;
}

.accept {
  padding: 10px 0 0 4px;
  display: flex;
  width: 270px;
  margin: 20px auto 0 auto;
}

.accept-text {
  text-align: left;
  a {
    color: #{$col-font-green};
  }
  margin: auto 0;
}

.social-btn-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 35px 1fr;
  grid-gap: 5px;
  span {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
}

.provider-icon {
  height: 32px;
  width: 32px;
  margin: -5px auto;
}

.email-icon {
  --stroke: white;
  height: 25px;
  width: 25px;
  margin: 0 auto;
}

.apple-icon {
  margin-bottom: 2px;
}

.divider-or {
  @extend .secondary-text;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 25px auto;
  color: $col-font;
  padding-top: 2px;
  .divider-text {
    margin: 0 10px;
  }
}

.divider-line {
  width: 100%;
  height: 1px;
  border-radius: $border-radius;
  background: $col-font;
}

.spinner-signin {
  width: 100px;
  height: 100px;
}
</style>
